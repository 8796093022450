(function() {
	$('header nav').doubleTapToGo({selectorClass: 'haschildren'});

	$(".hamburger").click(function(e){

        e.preventDefault();
        $("header nav").slideToggle("fast");
    })
    if ($(window).width() <= 960){
        $("header nav").hide();
    }
    $(window).resize(function(){
        if ($(window).width() > 960)
            $("header nav").show();
        else
            $("header nav").hide();
    });
}());
